<template>
    <div>
        <main-layout>
            <div class="layout-spacing">
                <div class="widget layout-spacing">

                    <div class="widget-heading">
                        
                        <!-- <h5 class="">Orders</h5> -->
                        
                            <!-- <form action="" method="post" class="float-right">
                                
                                <div class="form-row">
                                    <div class="col-auto">
                                        <select name="byStatus" id="byStatus" class="form-control form-control-sm">
                                            <option value="">Filter by Status</option>
                                            <option value="pending">Pending</option>
                                            <option value="in transit">In transit</option>
                                            <option value="completed">Completed</option>
                                        </select>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn btn-success">Filter</button>
                                    </div>
                                </div>
                            </form> -->
                            <button class="btn btn-outline-dark float-right" data-toggle="modal" data-target="#filter-modal">Filter</button>
                            <span class="h5">Orders <router-link class="btn btn-warning" to="/create-order"><i class="fa fa-plus"></i> New</router-link></span>
                    </div>

                    <div class="widget-content">
                        <div class="card shadow-sm mt-1 mb-1" v-for="o in orders" :key="o.orderID">
                            <div class="card-body">
                                <div class="">
                                    <p class="text-black" style="font-size: 1.0rem">
                                        <router-link :to="`/orders/${o.orderID}/view`">
                                            {{ o.item_description }}
                                        </router-link>
                                    </p>
                                    <p class="text-muted">Order ID: {{o.orderID }}</p>
                                    <p>To: {{o.delivery_name}} ({{o.delivery_address}})</p>
                                    <div>
                                        <span class="badge" :class="`${orderStatusesColorMappings[o.status] ?? 'outline-badge-primary'}`">{{ o.status }}</span>
                                    </div>
                                    <div>On {{ new Date(o.created_at).toDateString() }}</div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <simple-pagination :pagination="pagination" :callback="getOrders"></simple-pagination>
                    </div>
                </div>
            </div>
        </main-layout>
        <div class="modal fade" id="filter-modal" tabindex="-1" role="dialog" aria-labelledby="filter-modal" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <form @submit.prevent="getOrders" method="post">
                            <div class="form-row">
                                <div class="col form-group">
                                    <input type="search" name="search" id="search" class="form-control form-control-sm" placeholder="Search..." v-model="filterOptions.search">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-auto form-group">
                                    <select name="byStatus" id="byStatus" class="form-control form-control-sm" v-model="filterOptions.status">
                                        <option value="">Filter by Status</option>
                                        <option :value="s" v-for="(s, index) in allStatuses" :key="index">{{ s.toUpperCase() }}</option>
                                        <!-- <option value="pending">Pending</option>
                                        <option value="in transit">In transit</option>
                                        <option value="completed">Completed</option> -->
                                    </select>
                                </div>
                                <div class="col-auto form-group">
                                    <date-picker v-model="filterOptions.date_range" placeholder="Filter by date range" :range="true" input-class="form-control form-control-sm" format="YYYY-MM-DD" value-type="YYYY-MM-DD"></date-picker>
                                </div>
                                
                            </div>
                            <button class="btn btn-success" type="submit">Filter</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useToast } from 'vue-toastification';
import SimplePagination from '../../components/SimplePagination.vue';
import MainLayout from '../../layouts/MainLayout.vue';
import instance from '../../presenter/api/network';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    components: {MainLayout, SimplePagination, DatePicker},
    data(){
        return {
            message: "Welcome to chivita country",
            orderStatusesColorMappings: {
                pending: "badge-secondary",
                processing: "badge-info",
                unpaid: 'outline-badge-secondary',
                paid: "outline-badge-success",
                "in transit": "badge-primary",
                completed: "badge-success",
                canceled: "badge-danger"
            },
            orders: [],
            pagination: null,
            allStatuses: [],
            filterOptions: {
                search: "",
                date_range: "",
                status: ""
            }
        }
    },
    methods: {
        getOrders(page=1){
            
            this.$root.startRequestLoader();
            $("#filter-modal").modal("hide");
            let data = {
                page,
                status: this.filterOptions.status,
                any: this.filterOptions.search,
                date_from: this.filterOptions.date_range[0],
                date_to: this.filterOptions.date_range[1]
                // perpage: 1
            }
            instance.post("customer/orders", data).then(res => {
                this.orders = res.data.data;
                this.pagination = res.data.extra_data;
            }).catch(err => {
                useToast().error("There was an error fetching orders");
            }).finally(() => {
                this.$root.endRequestLoader();
            })
        },
        getAllStatus(){
            instance.get('order-status').then(res => {
                this.allStatuses = res.data.data;
            })
        },
    },
    mounted(){
        let page = this.$route.query.page ?? 1;
        this.filterOptions.status = this.$route.query.status ?? ''
        this.getOrders(page);
        this.getAllStatus();
    }
}
</script>